import { ProgressBar } from "primereact/progressbar";
import { Toast } from "primereact/toast";
import React, { useRef } from "react";
import { withMAQueuePollerProps } from "../components/hoc/withMAQueuePoller";
import { NavigationBar, SingleQueueBodyContainer as BodyContainer } from "../components/layout";
import { ConsultCard, NoConsultCard } from "../components/queue";
import { SessionExpire } from "../components/SessionExpire";
import { useSession } from "../functions/useSession";
import { create as createConsultCardProps } from "../types/components/queue/consultCardProps";
import { PaginatedConsultList } from "../types/consult-api";

export function Consults(props: { isLoading: boolean; consults: PaginatedConsultList | null; loadingMsg: string; noConsultsMsg: string; image?: string }) {
    return (
        <>
            {!props.consults && (
                <>
                    <ProgressBar mode="indeterminate" style={{ height: "6px", width: "95%", maxWidth: "696px", alignSelf: "center" }}></ProgressBar>
                    <NoConsultCard text={props.loadingMsg} />
                </>
            )}

            {props.consults && (props.consults.results?.length ?? 0) === 0 && <NoConsultCard text={props.noConsultsMsg} image={props.image} />}

            {props.consults &&
                (props.consults.results?.length ?? 0) > 0 &&
                props.consults.results?.map((consult, index) => (
                    <ConsultCard key={consult.guid} {...createConsultCardProps(consult, index)} index={index} disabled={false} />
                ))}
        </>
    );
}

export function MAQueue({ consultsPollerProps }: { consultsPollerProps: withMAQueuePollerProps }) {
    const [session, loading] = useSession();
    const toast = useRef<any>(null);

    if (!loading && !session) {
        location.href = "/auth/signin";
        return <></>;
    }

    if (loading) {
        return <ProgressBar mode="indeterminate" style={{ height: "4px" }} />;
    }

    return (
        <>
            {session?.expires && <SessionExpire />}
            <Toast ref={toast} />
            <NavigationBar />
            <BodyContainer color="#f8f8f8" paddingTop="1px">
                <div className="grid consult-queue-elements">
                    <div className="col-3 consult-queue-navigation" />
                    <div className="col-12 sm:col-9 queue-cards">
                        <Consults
                            isLoading={consultsPollerProps.loading.maConsults && !consultsPollerProps.loading.isTimerRefresh}
                            consults={consultsPollerProps.maConsults}
                            loadingMsg={"Looking for Care Team tasks..."}
                            noConsultsMsg={"There are no tasks available right now."}
                            image={"/no-consults-available.svg"}
                        />
                    </div>
                    <div className="col-3 consult-queue-filters" />
                </div>
            </BodyContainer>
        </>
    );
}

export default MAQueue;
