import { ConsultReasonForVisitEnum } from "../../consult-api";

const reasonMapping: { [key in ConsultReasonForVisitEnum]: { label: string; value: string } } = {
    [ConsultReasonForVisitEnum.Initial]: { label: "Initial", value: "initial" },
    [ConsultReasonForVisitEnum.FollowUp]: { label: "Follow Up", value: "follow_up" },
    [ConsultReasonForVisitEnum.SideEffect]: { label: "Side Effect", value: "side_effect" },
    [ConsultReasonForVisitEnum.PharmacyChange]: { label: "Pharmacy Change", value: "pharmacy_change" },
    [ConsultReasonForVisitEnum.MedicationChange]: { label: "Medication Change", value: "medication_change" },
    [ConsultReasonForVisitEnum.AdditionalDocumentation]: { label: "Additional Documentation", value: "additional_documentation" },
    [ConsultReasonForVisitEnum.LabResult]: { label: "Lab Result", value: "lab_result" },
    [ConsultReasonForVisitEnum.AdhocFollowUp]: { label: "Follow Up", value: "follow_up" },
    [ConsultReasonForVisitEnum.AdhocSideEffect]: { label: "Side Effect", value: "side_effect" },
    [ConsultReasonForVisitEnum.AdhocPharmacyChange]: { label: "Pharmacy Change", value: "pharmacy_change" },
    [ConsultReasonForVisitEnum.AdhocPharmacyCall]: { label: "Pharmacy Call", value: "adhoc_pharmacy_call" },
    [ConsultReasonForVisitEnum.AdhocMedicationChange]: { label: "Medication Change", value: "medication_change" },
    [ConsultReasonForVisitEnum.AdhocAdditionalDocumentation]: { label: "Additional Documentation", value: "additional_documentation" },
    [ConsultReasonForVisitEnum.Empty]: { label: "", value: "" },
    [ConsultReasonForVisitEnum.Null]: { label: "", value: "" },
};

export interface ConsultCardFilterProps {
    methods: {
        getReasonForVisitOptions: () => { label: string; value: string }[];
        onIsAsyncConsultsOnlyChange: (value?: boolean) => void;
        onConsultChange: (value?: string[]) => void;
        onPartnerChange: (value?: string[]) => void;
        onProgramChange: (value?: string[]) => void;
        onReasonForVisitChange: (value?: ConsultReasonForVisitEnum[]) => void;
    };
}

export function create(
    onIsAsyncConsultsOnlyChange: () => void,
    onConsultChange: () => void,
    onPartnerChange: () => void,
    onProgramChange: () => void,
    onReasonForVisitChange: () => void
): ConsultCardFilterProps {
    const uniqueOptions = new Set<string>();
    return {
        methods: {
            getReasonForVisitOptions: function () {
                return Object.keys(ConsultReasonForVisitEnum)
                    .map((key) => {
                        const value = ConsultReasonForVisitEnum[key as keyof typeof ConsultReasonForVisitEnum];
                        return reasonMapping[value];
                    })
                    .filter((option) => {
                        if (option.value !== "" && option.value !== "null" && !uniqueOptions.has(option.value)) {
                            uniqueOptions.add(option.value);
                            return true;
                        }
                        return false;
                    });
            },
            onIsAsyncConsultsOnlyChange,
            onConsultChange,
            onPartnerChange,
            onProgramChange,
            onReasonForVisitChange,
        },
    };
}
