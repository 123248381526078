import { ProgressBar } from "primereact/progressbar";
import { Toast } from "primereact/toast";
import React, { useContext, useRef } from "react";
import { withDefaultQueuePollerProps } from "../components/hoc/withDefaultQueuePoller";
import { BodyContainer, NavigationBar, SideNavigationButton } from "../components/layout";
import { ConsultCard, ConsultCardFilter, NoConsultCard } from "../components/queue";
import { SessionExpire } from "../components/SessionExpire";
import { FeatureToggleContext } from "../context/FeatureToggleContext";
import { isFeatureEnabled } from "../functions/consult/isFeatureEnabled";
import { useSession } from "../functions/useSession";
import { FeatureNameEnum } from "../hooks/useFeatureToggle";
import { create as createConsultCardFilterProps } from "../types/components/queue/consultCardFilterProps";
import { create as createConsultCardProps } from "../types/components/queue/consultCardProps";
import { PaginatedConsultList } from "../types/consult-api";

function Consults(props: {
    isLoading: boolean;
    consults: PaginatedConsultList | null;
    loadingMsg: string;
    noConsultsMsg: string;
    tabIndex: Number;
    image: string;
}) {
    return (
        <>
            {(!props.consults || props.isLoading) && (
                <>
                    <ProgressBar mode="indeterminate" style={{ height: "6px", width: "95%", maxWidth: "696px", alignSelf: "center" }}></ProgressBar>
                    <NoConsultCard text={props.loadingMsg} />
                </>
            )}

            {props.consults && !props.isLoading && (props.consults.results?.length ?? 0) === 0 && (
                <NoConsultCard text={props.noConsultsMsg} image={props.image} />
            )}

            {props.consults &&
                !props.isLoading &&
                (props.consults.results?.length ?? 0) > 0 &&
                props.consults.results?.map((consult, index) => <ConsultCard key={consult.guid} {...createConsultCardProps(consult, props.tabIndex, index)} />)}
        </>
    );
}

export function DefaultQueue({ consultsPollerProps }: { consultsPollerProps: withDefaultQueuePollerProps }) {
    const [session, loading] = useSession();
    const toast = useRef<any>(null);

    if (!loading && !session) {
        location.href = "/auth/signin";
        return <></>;
    }

    if (loading) {
        return <ProgressBar mode="indeterminate" style={{ height: "4px" }} />;
    }

    const myConsultsCount = consultsPollerProps.myConsults?.results?.length ?? 0;
    const otherConsultsCount = consultsPollerProps.otherConsults?.results?.length ?? 0;
    const maConsultsCount = consultsPollerProps.maConsults?.results?.length ?? 0;

    const { features } = useContext(FeatureToggleContext);

    return (
        <>
            {session?.expires && <SessionExpire />}
            <Toast ref={toast} />
            <NavigationBar />
            <BodyContainer color="#f8f8f8" paddingTop="1px">
                <div className="grid consult-queue-elements">
                    <div className="col-3 consult-queue-navigation">
                        <SideNavigationButton
                            isActive={consultsPollerProps.activeList === 0}
                            isLoading={!consultsPollerProps.myConsults}
                            text="Assigned Consults"
                            data-pr-tooltip={"Consults assigned to you"}
                            data-pr-position="right"
                            count={myConsultsCount}
                            onClick={() => {
                                if (myConsultsCount === 0) {
                                    return;
                                }
                                consultsPollerProps.methods.setActiveList(0);
                            }}
                            tooltip={"No assigned consults right now"}
                        />
                        <SideNavigationButton
                            isActive={consultsPollerProps.activeList === 1}
                            isLoading={!consultsPollerProps.otherConsults}
                            data-pr-tooltip={"Consults available to you"}
                            data-pr-position="right"
                            text="Available Consults"
                            count={otherConsultsCount}
                            onClick={() => {
                                if (otherConsultsCount === 0) {
                                    return;
                                }
                                consultsPollerProps.methods.setActiveList(1);
                            }}
                            tooltip={"No available consults right now"}
                        />
                        {isFeatureEnabled(features, FeatureNameEnum.CreateCareTeamTask) && (
                            <SideNavigationButton
                                isActive={consultsPollerProps.activeList === 2}
                                isLoading={!consultsPollerProps.maConsults}
                                data-pr-tooltip={"Consults with Care Team tasks"}
                                data-pr-position="right"
                                text="Care Team Tasks"
                                count={maConsultsCount}
                                onClick={() => {
                                    if (maConsultsCount === 0) {
                                        return;
                                    }
                                    consultsPollerProps.methods.setActiveList(2);
                                }}
                                tooltip={"No Care Team tasks right now"}
                            />
                        )}
                    </div>
                    <div className="col-12 sm:col-9 queue-cards">
                        {consultsPollerProps.activeList === 0 && (
                            <Consults
                                isLoading={consultsPollerProps.loading.myConsults && !consultsPollerProps.loading.isTimerRefresh}
                                consults={consultsPollerProps.myConsults}
                                loadingMsg={"Looking for your Assigned Consults..."}
                                noConsultsMsg={"There are no consults assigned to you right now."}
                                tabIndex={consultsPollerProps.activeList}
                                image={"/no-consults-available.svg"}
                            />
                        )}
                        {consultsPollerProps.activeList === 1 && (
                            <Consults
                                isLoading={consultsPollerProps.loading.otherConsults && !consultsPollerProps.loading.isTimerRefresh}
                                consults={consultsPollerProps.otherConsults}
                                loadingMsg={"Looking for Consults..."}
                                noConsultsMsg={"There are no consults right now. More consults will come in as they become available."}
                                tabIndex={consultsPollerProps.activeList}
                                image={"/no-consults-available.svg"}
                            />
                        )}
                        {consultsPollerProps.activeList === 2 && (
                            <Consults
                                isLoading={consultsPollerProps.loading.maConsults && !consultsPollerProps.loading.isTimerRefresh}
                                consults={consultsPollerProps.maConsults}
                                loadingMsg={"Looking for Care Team tasks..."}
                                noConsultsMsg={"There are no tasks assigned to the Care Team right now."}
                                tabIndex={consultsPollerProps.activeList}
                                image={"/no-consults-available.svg"}
                            />
                        )}
                    </div>
                    <div className="col-3 consult-queue-filters">
                        <ConsultCardFilter
                            {...createConsultCardFilterProps(
                                consultsPollerProps.methods.setIsAsyncConsultsOnly,
                                consultsPollerProps.methods.setConsult,
                                consultsPollerProps.methods.setPartner,
                                consultsPollerProps.methods.setProgram,
                                consultsPollerProps.methods.setReasonForVisit
                            )}
                        />
                    </div>
                </div>
            </BodyContainer>
        </>
    );
}

export default DefaultQueue;
