import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

const createConfig = function (opts?: any): AxiosRequestConfig {
    const config: AxiosRequestConfig = {};
    if (opts && opts.headers) {
        config.headers = opts.headers;
    }
    return config;
};

export function get(url: string, opts?: any): Promise<AxiosResponse> {
    return axios.get(url, createConfig(opts));
}

export function patch(url: string, data?: any, opts?: any): Promise<AxiosResponse> {
    return axios.patch(url, data, createConfig(opts));
}

export function post(url: string, data?: any, opts?: any): Promise<AxiosResponse> {
    return axios.post(url, data, createConfig(opts));
}

export function put(url: string, data?: any, opts?: any): Promise<AxiosResponse> {
    return axios.put(url, data, createConfig(opts));
}
