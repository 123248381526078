import { ProgressBar } from "primereact/progressbar";
import { Toast } from "primereact/toast";
import React, { useRef } from "react";
import { withNextUpQueuePollerProps } from "../components/hoc/withNextUpQueuePoller";
import { NavigationBar, SingleQueueBodyContainer as BodyContainer } from "../components/layout";
import { DaySchedule } from "../components/layout/nextUpQueue";
import { ConsultCard, NoConsultCard } from "../components/queue";
import { SessionExpire } from "../components/SessionExpire";
import { useSession } from "../functions/useSession";
import { create as createConsultCardProps } from "../types/components/queue/consultCardProps";
import { Consult, ConsultVisitStatusEnum, PaginatedConsultList } from "../types/consult-api";

export function Consults(props: { consults: PaginatedConsultList | null; loadingMsg: string; noConsultsMsg: string; tabIndex: Number; image?: string }) {
    function isButtonDisabled(consult: Consult, index: Number) {
        const isNextUpConsult = index === 0;
        return (
            !isNextUpConsult &&
            ![
                ConsultVisitStatusEnum.Scheduled,
                ConsultVisitStatusEnum.ReadyToResume,
                ConsultVisitStatusEnum.InProgress,
                ConsultVisitStatusEnum.ExternalInProgress,
            ].includes(consult.visitStatus)
        );
    }

    return (
        <>
            {!props.consults && (
                <>
                    <ProgressBar mode="indeterminate" style={{ height: "6px", width: "95%", maxWidth: "696px", alignSelf: "center" }}></ProgressBar>
                    <NoConsultCard text={props.loadingMsg} />
                </>
            )}

            {props.consults && (props.consults.results?.length ?? 0) === 0 && <NoConsultCard text={props.noConsultsMsg} image={props.image} />}

            {props.consults &&
                (props.consults.results?.length ?? 0) > 0 &&
                props.consults.results?.map((consult, index) => (
                    <ConsultCard
                        key={consult.guid}
                        {...createConsultCardProps(consult, props.tabIndex, index)}
                        index={index}
                        disabled={isButtonDisabled(consult, index)}
                    />
                ))}
        </>
    );
}

export function NextUpQueue({ consultsPollerProps }: { consultsPollerProps: withNextUpQueuePollerProps }) {
    const [session, loading] = useSession();
    const toast = useRef<any>(null);

    if (!loading && !session) {
        location.href = "/auth/signin";
        return <></>;
    }

    if (loading) {
        return <ProgressBar mode="indeterminate" style={{ height: "4px" }} />;
    }

    return (
        <>
            {session?.expires && <SessionExpire />}
            <Toast ref={toast} />
            <NavigationBar />
            <BodyContainer color="#f8f8f8" paddingTop="1px">
                <div
                    className="single-queue-top-section"
                    style={{
                        backgroundColor: "#BDE6EA",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignContent: "center",
                    }}
                />
                <div className="grid single-queue-elements">
                    <div className="col-3 single-queue-schedule">
                        <DaySchedule consults={consultsPollerProps.nextUpScheduledConsults} />
                    </div>
                    <div className="col-6 single-queue-cards">
                        <Consults
                            consults={consultsPollerProps.nextUpConsults}
                            loadingMsg={"Looking for your next consult..."}
                            noConsultsMsg={"There are no consults available right now."}
                            tabIndex={0}
                            image={"/no-consults-available.svg"}
                        />
                    </div>
                    <div className="col-3 single-queue-schedule" />
                </div>
            </BodyContainer>
        </>
    );
}

export default NextUpQueue;
