import useIntervalWhen from "@rooks/use-interval-when";
import moment from "moment/moment";
import { FC } from "react";
import { useSession } from "../../functions/useSession";
import { post } from "../../functions/xhr";
import { AWSRumEventTypeEnum, recordEvent } from "../../public/scripts/rum";

const withPresenceHeartbeat = (Component: FC<any>) => (props: any) => {
    const [session] = useSession();
    const sessionUser = session?.user as any;

    const heartbeat = async function () {
        if (session && moment() > moment.utc(session.expires)) {
            return;
        }

        try {
            await post("/api/clinicianLastSeenAt", { clinicianGuid: sessionUser.clinician.guid });
        } catch (err) {}

        recordEvent(AWSRumEventTypeEnum.ClinicianLastSeenAt, { clinicianGuid: sessionUser.clinician.guid });
    };
    useIntervalWhen(heartbeat, 5 * 60 * 1000, !!session);

    return <Component {...props} />;
};

export default withPresenceHeartbeat;
