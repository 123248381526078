import moment from "moment";
import { useRouter } from "next/router";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { FC, useEffect, useRef, useState } from "react";
import { getMaConsults } from "../../functions/getConsults";
import { useSession } from "../../functions/useSession";
import { AWSRumEventTypeEnum, recordEvent } from "../../public/scripts/rum";
import { ConsultReasonForVisitEnum, PaginatedConsultList } from "../../types/consult-api";

export interface withMAQueuePollerProps {
    maConsults: PaginatedConsultList | null;
    loading: { isTimerRefresh: boolean; maConsults: boolean };
    methods: {
        setConsult: (value?: string[]) => void;
    };
}

const withMAQueuePoller = (Component: FC<any>) => (props: any) => {
    const [session] = useSession();
    const router = useRouter();
    const toast = useRef<Toast | null>(null);

    const [maConsults, setMaConsults] = useState<PaginatedConsultList | null>(null);
    const [loading, setLoading] = useState({
        isTimerRefresh: true,
        maConsults: false,
    });

    const isAsyncConsultsOnlyFilter = useRef<boolean | undefined>();
    const consultFilter = useRef<string[] | undefined>();
    const partnerFilter = useRef<string[] | undefined>();
    const programFilter = useRef<string[] | undefined>();
    const reasonForVisitFilter = useRef<ConsultReasonForVisitEnum[] | undefined>();
    const errorMessage = useRef<string>("");

    const mapErrorMessageToTag = (msg: string) => {
        if (msg == "Unavailable Consult")
            return (
                <span className={"text-sm"}>
                    This consult is no longer available.
                    <Button
                        className="p-button-sm p-button-danger return-to-queue-toast-button"
                        label="Return to Queue"
                        onClick={() => {
                            window.location.assign("/");
                        }}
                    />
                </span>
            );
        else return msg;
    };

    const handleError = (error: any) => {
        if (error.message !== errorMessage.current) {
            errorMessage.current = error.message;
            toast.current?.show({
                severity: "error",
                sticky: true,
                summary: "Uh oh!",
                detail: mapErrorMessageToTag(error.message),
                className: "error-toast",
            });
        }
    };
    const list = useRef<any>();
    list.current = { maConsults };

    useEffect(() => {
        const newLoading = { ...loading };
        newLoading.maConsults = false;
        setLoading(newLoading);
    }, [maConsults]);

    const refresh = async (isTimerRefresh: boolean) => {
        setLoading({ isTimerRefresh, maConsults: true });
        try {
            const [maConsults] = await Promise.all([
                // @ts-ignore
                getMaConsults(
                    consultFilter.current,
                    partnerFilter.current,
                    programFilter.current,
                    reasonForVisitFilter.current,
                    isAsyncConsultsOnlyFilter.current
                )
                    .then((result) => {
                        setMaConsults(result);
                        return result;
                    })
                    .catch((error) => {
                        handleError(error);
                        setMaConsults({ next: "", previous: "", results: [] });
                        return null;
                    }),
            ]);
            if (maConsults) {
                if (maConsults.results?.length == 0 && consultFilter.current && consultFilter.current.length > 0) {
                    const error = { message: "Unavailable Consult" };
                    handleError(error);
                }
            }
        } catch (error) {
            handleError(error);
            // Handle unexpected errors
        } finally {
            setLoading({ isTimerRefresh, maConsults: false });
        }
    };

    useEffect(() => {
        const timer = setInterval(() => {
            if (session && moment.utc(session.expires) > moment()) {
                refresh(true);
            }
        }, 30 * 1000);
        return () => clearInterval(timer);
    }, [router.isReady, router.query, session]);

    useEffect(() => {
        if (router.isReady && session) {
            const consult = router.query.consult;
            if (consult) {
                consultFilter.current = Array.isArray(consult) ? consult : [consult];
            }
            refresh(true);
            recordEvent(AWSRumEventTypeEnum.ConsultDashboardEntered);
        }
    }, [router.isReady, router.query, session]);

    const pollerProps: withMAQueuePollerProps = {
        maConsults,
        loading,
        methods: {
            setConsult: (value) => {
                if (consultFilter.current !== value) {
                    consultFilter.current = value;
                    refresh(false);
                }
            },
        },
    };
    return (
        <>
            <Toast ref={toast} />
            <Component {...props} consultsPollerProps={pollerProps} />
        </>
    );
};

export default withMAQueuePoller;
